import type { Endpoints, Search, SearchResults, Suggest } from '~/types'

export const useSearchStore = defineStore('useSearchStore', () => {
  const result = {
    pages_count: 0,
    current_page: 0,
    results: [],
  }

  const defaultResults: SearchResults = {
    community: result,
    article: result,
    'avis-joueurs': result,
    'avis-medias': result,
    'actualites-communaute': result,
    astuces: result,
    'le-coin-des-wapers': result,
    offer: result,
    user: result,
  }

  // STATE
  const results = ref<SearchResults>({ ...defaultResults })
  const request = ref<string>()
  const suggests = ref<Suggest[]>([])

  // MUTATIONS
  const setResults = (update: { results: SearchResults; request?: string }) => {
    results.value = update.results
    request.value = update.request
  }

  // ACTIONS
  const clearSearch = () => {
    setResults({ results: { ...defaultResults }, request: undefined })
  }

  const getSuggest = (searchItem: Search) => {
    switch (searchItem.type) {
      case 'post_review':
        return {
          id: `post_review_${searchItem.object.slug}`,
          type: 'Avis de joueurs',
          title: searchItem.object.title,
          route: `/communautes/${searchItem.object.community.slug}/${searchItem.object.category.slug}/detail/${searchItem.object.slug}`,
        }
      case 'user':
        return {
          id: `waper_${searchItem.object.nickname}`,
          type: 'Waper',
          title: searchItem.object.nickname,
          route: `/wapers/${searchItem.object.slug}`,
        }
      case 'community':
        return {
          id: `community_${searchItem.object.slug}`,
          type: 'Communauté',
          title: searchItem.object.title,
          route: `/communautes/${searchItem.object.slug}`,
        }
      case 'article':
        return {
          id: `article_${searchItem.object.slug}`,
          type: 'Actu PlayStation',
          title: searchItem.object.title,
          route: `/actualites-playstation/${searchItem.object.slug}`,
        }
      case 'offer':
        return {
          id: `offer_${searchItem.object.slug}`,
          type: 'Offre',
          title: searchItem.object.title,
          route: `/offres/${searchItem.object.slug}`,
        }
      case 'post':
        switch (searchItem.object.category.slug) {
          case 'actualites-communaute':
          case 'avis-medias':
            return {
              id: `${searchItem.object.category.slug}_${searchItem.object.slug}`,
              type:
                searchItem.object.category.slug === 'avis-medias'
                  ? 'avis medias'
                  : 'actualités communauté',
              title: searchItem.object.title,
              route: `/communautes/${searchItem.object.community.slug}/${searchItem.object.category.slug}/${searchItem.object.slug}`,
            }
          case 'astuces':
            return {
              id: `${searchItem.object.category.slug}_${searchItem.object.slug}`,
              type: 'astuces',
              title: searchItem.object.title,
              route: `/communautes/${searchItem.object.community.slug}/${
                searchItem.object.category.slug
              }/${
                searchItem.object.sub_category
                  ? searchItem.object.sub_category.slug
                  : 'noData'
              }/${searchItem.object.slug}`,
            }
          case 'le-coin-des-wapers':
            return {
              id: `${searchItem.object.category.slug}_${searchItem.object.slug}`,
              type: 'le feed des players',
              title: searchItem.object.title,
              route: `/communautes/${searchItem.object.community.slug}/le-feed-des-players/${
                searchItem.object.sub_category
                  ? searchItem.object.sub_category.slug
                  : 'noData'
              }/${searchItem.object.slug}`,
            }
          default:
            break
        }

        break

      default:
        break
    }
  }

  const fetchSuggest = async (query: string) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__SEARCH_AUTOCOMPLETE']
    >(API.GET__SEARCH_AUTOCOMPLETE, {
      query: {
        q: query,
      },
    })

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem searching results: ',
        error,
      )
    }

    suggests.value = rawData.flatMap(
      (searchItem) => getSuggest(searchItem) ?? [],
    )
  }

  const fetchMoreCommunities = async () => {
    const page = results.value.community.current_page + 1
    const { data, error } = await useCustomFetch<
      Endpoints['GET__COMMUNITY_SEARCH']
    >(API.GET__COMMUNITY_SEARCH, {
      query: {
        page,
        q: request.value,
      },
    })

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.slug}`,
    }))

    results.value.community.results.push(...formattedData)
    results.value.community.current_page++
  }

  const fetchMoreArticles = async () => {
    const page = results.value.article.current_page + 1
    const { data, error } = await useCustomFetch<
      Endpoints['GET__ARTICLE_SEARCH']
    >(API.GET__ARTICLE_SEARCH, {
      query: {
        page,
        q: request.value,
      },
    })

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/actualites-playstation/${el.slug}`,
    }))

    results.value.article.results.push(...formattedData)
    results.value.article.current_page++
  }

  const fetchMoreReviews = async () => {
    const page = results.value['avis-joueurs'].current_page + 1
    const { data, error } = await useCustomFetch<
      Endpoints['GET__REVIEWS_SEARCH']
    >(API.GET__REVIEWS_SEARCH, {
      query: {
        page,
        q: request.value,
      },
    })

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.community?.slug}/${el.category?.slug}/detail/${el.slug}`,
    }))

    results.value['avis-joueurs'].results.push(...formattedData)
    results.value['avis-joueurs'].current_page++
  }

  const fetchMoreMedias = async () => {
    const page = results.value['avis-medias'].current_page + 1
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          category_slug: 'avis-medias',
          q: request.value,
        },
      },
    )

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.community.slug}/${el.category.slug}/${el.slug}`,
    }))

    results.value['avis-medias'].results.push(...formattedData)
    results.value['avis-medias'].current_page++
  }

  const fetchMoreNews = async () => {
    const page = results.value['actualites-communaute'].current_page + 1
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          category_slug: 'actualites-communaute',
          q: request.value,
        },
      },
    )

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.community.slug}/${el.category.slug}/${el.slug}`,
    }))

    results.value['actualites-communaute'].results.push(...formattedData)
    results.value['actualites-communaute'].current_page++
  }

  const fetchMoreTips = async () => {
    const page = results.value.astuces.current_page + 1
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          category_slug: 'astuces',
          q: request.value,
        },
      },
    )

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.community.slug}/${el.category.slug}/${el.slug}`,
    }))

    results.value.astuces.results.push(...formattedData)
    results.value.astuces.current_page++
  }

  const fetchMoreWaperPosts = async () => {
    const page = results.value['le-coin-des-wapers'].current_page + 1
    const { data, error } = await useCustomFetch<Endpoints['GET__POST_SEARCH']>(
      API.GET__POST_SEARCH,
      {
        query: {
          page,
          category_slug: 'le-coin-des-wapers',
          q: request.value,
        },
      },
    )

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/communautes/${el.community.slug}/${el.category.slug}/${el.slug}`,
    }))

    results.value['le-coin-des-wapers'].results.push(...formattedData)
    results.value['le-coin-des-wapers'].current_page++
  }

  const fetchMoreOffers = async () => {
    const page = results.value.offer.current_page + 1
    const { data, error } = await useCustomFetch<
      Endpoints['GET__OFFERS_SEARCH']
    >(API.GET__OFFERS_SEARCH, {
      query: {
        page,
        q: request.value,
      },
    })

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/offres/${el.slug}`,
    }))

    results.value.offer.results.push(...formattedData)
    results.value.offer.current_page++
  }

  const fetchMoreUsers = async () => {
    const page = results.value.user.current_page + 1
    const { data, error } = await useCustomFetch<Endpoints['GET__USER_SEARCH']>(
      API.GET__USER_SEARCH,
      {
        query: {
          page,
          q: request.value,
        },
      },
    )

    const rawData = unref(data.value)

    if (error.value || !rawData) {
      return logError(
        'from search actions',
        'There was a problem searching results: ',
        error,
      )
    }
    const formattedData = rawData.results.map((el) => ({
      ...el,
      route: `/wapers/${el.slug}`,
    }))

    results.value.user.results.push(...formattedData)
    results.value.user.current_page++
  }

  const fetchSearch = async (query: string) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__SEARCH']>(
      API.GET__SEARCH,
      {
        query: {
          q: query,
        },
      },
    )

    const rawData = unref(data)

    if (error.value || !rawData) {
      return logError(
        'from actions',
        'There was a problem searching results: ',
        error,
      )
    }

    const tempResults = { ...defaultResults }

    rawData.forEach((searchItem) => {
      switch (searchItem.type) {
        case 'community':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/communautes/${el.slug}`,
            })),
          }
          break
        case 'article':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/actualites-playstation/${el.slug}`,
            })),
          }
          break
        case 'avis-joueurs':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/communautes/${el.community?.slug}/${el.category?.slug}/detail/${el.slug}`,
            })),
          }
          break
        case 'avis-medias':
        case 'actualites-communaute':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/communautes/${el.community.slug}/${el.category.slug}/${el.slug}`,
            })),
          }
          break

        case 'astuces':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/communautes/${el.community.slug}/${el.category.slug}/${
                el.sub_category ? el.sub_category.slug : 'noData'
              }/${el.slug}`,
            })),
          }

          break

        case 'le-coin-des-wapers':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/communautes/${el.community.slug}/le-feed-des-players/${
                el.sub_category ? el.sub_category.slug : 'noData'
              }/${el.slug}`,
            })),
          }

          break
        case 'offer':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/offres/${el.slug}`,
            })),
          }

          break
        case 'user':
          tempResults[searchItem.type] = {
            pages_count: searchItem.response.pages_count,
            current_page: 1,
            results: searchItem.response.results.map((el) => ({
              ...el,
              route: `/wapers/${el.slug}`,
            })),
          }

          break
        default:
          break
      }
    })

    setResults({ results: tempResults, request: query })
  }

  return {
    //State
    results,
    request,
    suggests,

    //Actions
    clearSearch,
    fetchSuggest,
    fetchMoreOffers,
    fetchMoreUsers,
    fetchMoreCommunities,
    fetchMoreArticles,
    fetchMoreReviews,
    fetchMoreMedias,
    fetchMoreNews,
    fetchMoreTips,
    fetchSearch,
    fetchMoreWaperPosts,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
